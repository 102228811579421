import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"

import SubpageHero from "components/SubpageHero"
import Breadcrumbs from "components/Breadcrumbs"
import SubpageBack from "components/SubpageBack"

import {
  Content,
  Realizations,
  Publications,
  Rankings,
} from "page_components/about"

const About = ({ data, location }) => {
  const pageData = data?.allWpPage?.nodes?.[0]?.acfAbout

  return (
    <Layout
      location={location}
      seo={{
        title: "O kancelarii",
      }}
    >
      <SubpageHero title="Kim jesteśmy" />
      <Breadcrumbs title="O kancelarii" />
      <Content />
      <Realizations data={pageData?.aboutRealizations} />
      <Publications data={pageData?.aboutPublications} />
      <Rankings data={pageData?.aboutRankings} />
      <SubpageBack />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDo4MDU=" } }) {
      nodes {
        acfAbout {
          aboutRealizations {
            description
          }
          aboutPublications {
            title
            description
          }
          aboutRankings {
            logo {
              sourceUrl
            }
            content {
              description
              logos {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`

export default About
